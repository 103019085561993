<template lang="pug">
  v-card(outlined)
    v-card-text.text-center.justify-center
      v-icon(color="secondary" large) mdi-note-edit-outline
      div.text-body-1.font-weight-medium.mb-3 Documento Tributario
      div(v-if="tipo === 'factura_exenta'")
        div.text-body-2.primary--text En caso de requerir, seleccione el documento tributario:
        v-row(justify="center")
          v-col(cols="auto")
            v-checkbox(v-model="dte" label="Factura exenta" true-value="factura_exenta" false-value="ninguno" color="secondary")
      div(v-else)
        div.text-body-2.primary--text Seleccione el documento tributario:
        v-row(justify="center")
          v-col(cols="auto")
            v-radio-group(v-model="dte" row :error="err", :error-messages="errMsg" @change="onChange")
              v-radio(label="Boleta" value="boleta" color="secondary")
              v-radio(label="Factura mixta" value="factura_mixta" color="secondary")
      v-expand-transition
        v-form(ref="datos_factura" v-if="dte === 'factura_exenta' || dte === 'factura_mixta'")
          v-row
            v-col(cols="12" sm="6")
              v-text-field(v-model="rut" label="RUT" outlined prepend-icon="mdi-card-account-details" required :rules="rules(true, 'RUT')" color="secondary" @blur="onBlurRut")
            v-col(cols="12" sm="6")
              v-text-field(v-model="razonSocial" label="Razón social" outlined prepend-icon="mdi-form-textbox" required :rules="rules(true)" color="secondary")
            v-col(cols="12" sm="6")
              v-text-field(v-model="giro" label="Giro" outlined prepend-icon="mdi-cog" required :rules="rules(true)" color="secondary")
            v-col(cols="12" sm="6")
              v-text-field(v-model="direccion" label="Dirección" outlined prepend-icon="mdi-map-marker" required :rules="rules(true)" color="secondary")
            v-col(cols="12" sm="6")
              v-text-field(v-model="ciudad" label="Ciudad" outlined prepend-icon="mdi-city" required :rules="rules(true)" color="secondary")
            v-col(cols="12" sm="6")
              v-text-field(v-model="comuna" label="Comuna" outlined prepend-icon="mdi-city" required :rules="rules(true)" color="secondary")
</template>

<script>

import { format } from 'rut.js'

export default {
  name: 'WidgetDTE',

  props: {
    p: {
      type: Number,
      default: 0
    },

    v: {
      type: Number,
      default: 0
    },

    c: {
      type: Number,
      default: 0
    },
  },

  components: {
  },

  data: () => ({
    err: false,
    errMsg: '',
    rut: '',
    razonSocial: '',
    giro: '',
    direccion: '',
    ciudad: '',
    comuna: ''
  }),

  computed: {
    tipo () {
      if (this.p && !this.v && !this.c) {
        return 'factura_exenta'
      }

      if ((this.v || this.c) && this.p) {
        return 'factura_mixta'
      }

      if ((this.v || this.c) && !this.p) {
        // No usado actualmente porque es obligatorio comprar con 1 pasajero
        return 'factura_afecta'
      }

      return 'factura_mixta'
    },

    dte: {
      get () {
        return this.$store.state.dte
      },

      set (val) {
        this.$store.commit('SET_DTE', val)
      }
    }
  },

  mounted () {
  },

  methods: {
    onChange () {
      this.err = false
      this.errMsg = ''
    },

    onBlurRut () {
      this.rut = format(this.rut, { dots: false })
    },

    validar () {
      if (this.tipo === 'factura_exenta') {
        if (this.dte === 'factura_exenta') {
          return this.validar_factura()
        } else {
          this.$store.commit('SET_DATOS_FACTURA', {})
          return true
        }
      }

      if (this.tipo === 'factura_mixta' && this.dte === 'ninguno') {
        this.err = true
        this.errMsg = 'Requerido'
        this.$store.commit('SET_DATOS_FACTURA', {})
        return false
      } else {
        this.err = false
        this.errMsg = ''
      }

      if (this.dte === 'ninguno' || this.dte === '' || this.dte === 'boleta') {
        this.$store.commit('SET_DATOS_FACTURA', {})
        return true
      } else {
        return this.validar_factura()
      }
    },

    validar_factura () {
      const flag = this.$refs['datos_factura'].validate()
      if (flag) {
        const datos = {
          rut: this.rut,
          razonSocial: this.razonSocial,
          giro: this.giro,
          direccion: this.direccion,
          comuna: this.comuna,
          ciudad: this.ciudad
        }
        this.$store.commit('SET_DATOS_FACTURA', datos)
      } else {
        this.$store.commit('SET_DATOS_FACTURA', {})
      }
      return flag
    }
  }
}

</script>

<style type="text/css">

</style>
