<template lang="pug">
  Detalle(@prev="prev")
</template>

<script>

import Detalle from '@/components/UI/Detalle'

export default {
  name: 'Paso4',

  components: {
    Detalle
  },

  data: () => ({
  }),

  watch: {
  },

  computed: {
    total () {
      return this.$store.state.vehiculosArr.length + this.$store.state.cargasArr.length
    }
  },

  methods: {
    prev () {
      let n = 3
      if (this.total === 0) {
        n = 2
      }
      this.$store.commit('SET_PASO', n)
      this.$router.push('/paso' + n)
    }
  }
}

</script>

<style type="text/css">

</style>
