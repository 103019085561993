<template lang="pug">
  v-card(v-if="!cargando")
    v-card-title
      v-tabs(v-model="tabOriginal" color="secondary" background-color="primary" slider-color="secondary" slider-size="8" fixed-tabs dark height="60")
        v-tab(v-if="trayecto" v-for="(trayecto, t) in capacidades", :key="t")
          | {{ tipo(t) }}
          v-badge.ml-2(v-if="errores[t]" color="error" dot)
    v-card-text
      v-tabs-items(v-model="tabOriginal")
        v-tab-item(v-for="(trayecto, t) in capacidades", :key="t")
          div.my-6.text-h6.font-weight-light Elija <b>{{ pasajeros?.length }}</b> butaca{{ pasajeros?.length === 1 ? '' : 's' }} para el viaje de <b>{{ tipo(tab) }}</b>:
          v-row
            v-col(cols="12" md="6")
              div.text-body-1.font-weight-light Nave: <b>{{ itinerario[tab].recurso.nombre }}</b>
              div.text-body-1.font-weight-light Asientos disponibles: <b>{{ butacas_disponibles(trayecto) }}</b>
              div.text-body-1.font-weight-light Asientos seleccionados: <b>{{ n[tab] }}</b>
            v-col(cols="12" md="6")
              v-card(outlined)
                div.text-center.overline Simbología Butacas
                v-card-text.py-0
                  div.my-2.d-flex
                    div.mr-2.butaca
                    div.text-caption Disponible
                  // div.my-2.d-flex
                    div.mr-2.butaca_preferencial
                    div.text-caption Preferencial
                  div.my-2.d-flex
                    div.mr-2.butaca.nodisponible
                    div.text-caption No disponible
          div.mt-6.butacas
            div.text-overline.text-center Proa
            div.text-center.justify-center(v-if="ready" v-for="r in barco?.model?.length", :key="r")
              div(v-for="c in barco?.model[r-1]?.length", :key="c", :title="butacasArr[r-1][c-1].title" v-bind:class="getClass(butacasArr[r-1][c-1])" @click="clickButaca(r-1, c-1)")
            div.text-overline.text-center Popa
    v-card-actions
      v-spacer
      v-btn.mx-3.mb-3(color="primary" @click="$emit('cerrar')")
        v-icon(left) mdi-check
        | ACEPTAR

  v-card(v-else min-height="600")
    v-card-text
      v-row.fill-height.text-center(align-content="center" justify="center")
        v-col(cols="12")
          div.text-body-1 Buscando butacas disponibles...
        v-col(v-if="!error" cols="8")
          v-progress-linear(color="secondary" indeterminate rounded)
        v-col(v-else cols="8")
          v-btn(color="primary" @click="iniciar") Reintentar
            v-icon(right) mdi-refresh
</template>

<script>

import barcos from '@/assets/barcos'

export default {
  name: 'Butacas',

  components: {
  },

  data: () => ({
    tabOriginal: 0,
    barcos: barcos,
    butacas: [],
    butacas_ida: [],
    butacas_vuelta: [],
    ready: false,
    cargando: false,
    n: [0, 0],
    errores: [false, false],
    error: false
  }),

  watch: {
  },

  computed: {
    capacidades () {
      return this.$store.state.butacas
    },

    itinerario () {
      return this.$store.state.itinerario
    },

    barco () {
      return this.barcos[this.itinerario[this.tab].recurso.nombre.toLowerCase()]
    },

    butacasArr () {
      if (this.tab === 1) {
        return this.butacas_vuelta
      } else {
        return this.butacas_ida
      }
    },

    pasajeros () {
      return this.$store.state.pasajerosArr
    },

    tipo_viaje () {
      return this.$store.state.tipo_viaje
    },

    loading () {
      return this.$store.state.prereservaLoading
    },

    tab () {
      if (this.$store.state.itinerario?.[0]?.butacas && this.$store.state.itinerario?.[1]?.butacas) {
        return this.tabOriginal
      }

      if (this.$store.state.itinerario?.[0]?.butacas) {
        return 0
      }

      return 1
    }
  },

  mounted () {
    this.iniciar()
  },

  methods: {
    async iniciar () {
      this.error = false
      this.n = [0, 0]
      this.inicializar_butacas()
      this.marcar_butacas_ocupadas()
      this.marcar_butacas_seleccionadas()
      this.ready = true
    },

    inicializar_butacas () {
      this.butacas_ida = []
      this.butacas_vuelta = []
      this.butacas = []
      for (let i = 0; i < this.itinerario.length; i++) {
        if (!this.itinerario[i] || !this.itinerario[i].butacas) {
          this.butacas.push([])
          continue
        }
        this.butacas.push({})
        const nave = this.itinerario[i].recurso.nombre.toLowerCase()
        const barco = this.barcos[nave]
        let butacas
        if (i === 1) {
          butacas = this.butacas_vuelta
        } else {
          butacas = this.butacas_ida
        }

        for (let r = 0; r < barco.model.length; r++) {
          const fila = []
          for (let c = 0; c < barco.model[r].length; c++) {
            const asiento = barco.model[r][c]
            const butaca = {
              'disabled': asiento === '_',
              'nodisponible': asiento === '_',
              'checked': false,
              'title': asiento !== '_' ? asiento : undefined,
            }
            butaca.class = butaca.disabled ? 'disabled' : ''
            fila.push(butaca)
            if (butaca.title) {
              this.butacas[i][butaca.title] = butaca
            }
          }
          butacas.push(fila)
        }
      }
    },

    marcar_butacas_ocupadas () {
      for (let c = 0; c < this.capacidades.length; c++) {
        if (!this.butacas[c] || !this.capacidades[c] || !this.capacidades[c].butacas) {
          continue
        }

        for (let b = 0; b < this.capacidades[c].butacas.length; b++) {
          const numero = this.capacidades[c].butacas[b]
          if (!this.butacas[c][numero]) {
            continue
          }
          this.butacas[c][numero].nodisponible = true
          this.butacas[c][numero].class = 'nodisponible'
          this.butacas[c][numero].label = numero
        }
      }
    },

    marcar_butacas_seleccionadas () {
      for (let c = 0; c < this.capacidades.length; c++) {
        for (let p = 0; p < this.pasajeros.length; p++) {
          if (this.pasajeros[p].butacas && this.pasajeros[p].butacas[c]) {
            const butaca = this.pasajeros[p].butacas[c]
            if (butaca) {
              this.butacas[c][butaca].checked = true
              this.butacas[c][butaca].class = 'checked'
              this.butacas[c][butaca].nodisponible = false
              this.n[c]++
            }
          }
        }
      }
    },

    limpiar_butacas_seleccionadas () {
      for (let c = 0; c < this.capacidades.length; c++) {
        const tipo = this.tipo(c)
        for (let p = 0; p < this.pasajeros.length; p++) {
          this.pasajeros[p][tipo] = undefined
        }
      }
    },

    tipo (t) {
      if (t === 1) {
        return 'vuelta'
      } else {
        return 'ida'
      }
    },

    clickButaca(r, c) {
      let butaca = this.butacasArr[r][c]
      let index

      if (butaca.nodisponible) {
        return
      }

      // Activar selección
      if (!butaca.checked) {
        if (this.n[this.tab] < this.pasajeros.length) {
          index = this.pasajeros.findIndex(el => el.butacas === undefined || el.butacas[this.tab] === null)
          let butacas
          if (!this.pasajeros[index].butacas) {
            butacas = [null, null]
          } else {
            butacas = JSON.parse(JSON.stringify(this.pasajeros[index].butacas))
          }
          butacas[this.tab] = butaca.title
          let obj = {
            index,
            data: {
              butacas
            }
          }
          this.$store.commit('SET_BUTACA_PASAJERO', obj)
          this.n[this.tab]++
          if (this.n[this.tab] === this.pasajeros.length) {
            this.errores[this.tab] = false
            this.$forceUpdate()
          }
          butaca.checked = true
          butaca.class = 'checked'
        }
      } else {
        // Desactivar selección
        index = this.pasajeros.findIndex(el => el.butacas === undefined || el.butacas[this.tab] === butaca.title)
        let butacas
        if (!this.pasajeros[index].butacas) {
          butacas = [null, null]
        } else {
          butacas = JSON.parse(JSON.stringify(this.pasajeros[index].butacas))
        }
        butacas[this.tab] = null
        let obj = {
          index,
          data: {
            butacas
          }
        }
        this.$store.commit('SET_BUTACA_PASAJERO', obj)
        this.n[this.tab]--
        butaca.checked = false
        butaca.class = ''
      }
    },

    butacas_disponibles (trayecto) {
      return (trayecto?.max - trayecto?.butacas?.length) || 0
    },

    getClass (butaca) {
      let clase = butaca.class || ''
      if (this.barco.preferenciales?.includes(butaca.title)) {
        clase += ' butaca_preferencial'
      }
      clase += ' butaca'
      return clase
    },

    deshabilitar () {
      if (this.$store.state.tipo_viaje === 'ida') {
        let flag = this.pasajeros.length !== this.n[0]
        this.errores[0] = flag
        return flag
      } else {
        let flag0 = this.pasajeros.length !== this.n[0]
        let flag1 = this.pasajeros.length !== this.n[1]
        this.errores[0] = flag0
        this.errores[1] = flag1
        return flag0 || flag1
      }
    },

    validar () {
      if (this.deshabilitar()) {
        let txt = 'Seleccione todas las butacas'
        if (this.tipo_viaje === 'vuelta') {
          txt = 'Seleccione todas las butacas de ida y de vuelta'
        }
        this.$store.dispatch('notify', {
          color: 'error',
          text: txt,
          icon: 'mdi-alert-circle',
          timeout: 5000
        })
        this.$forceUpdate()
        return
      }
      this.continuar()
    },

    async continuar () {
      await this.$store.dispatch('preReserva')
      this.$emit('next')
    }
  }
}

</script>

<style type="text/css">

.butacas {
  overflow: auto;
  white-space: nowrap;
  height: 100%;
  border: solid 1px #6c6c6c;
}

.butaca {
  background: url('../../../public/images/morado_disponible.png') no-repeat;
  width: 20px;
  height: 20px;
  margin: 1px;
  cursor: pointer;
  display: inline-flex;
}

.butaca_preferencial {
  background: url('../../../public/images/preferencial_disponible.png') no-repeat;
  width: 20px;
  height: 20px;
  margin: 1px;
  cursor: pointer;
  display: inline-flex;
}

.butaca.disabled {
  opacity: 0;
  width: 20px;
  height: 20px;
  cursor: default;
}

.butaca.checked {
  background: url('../../../public/images/morado_seleccionado.png') no-repeat;
  border-radius: 0px;
  line-height: 0px;
  border: 0px;
  width: 20px;
  height: 20px;
}

.butaca_preferencial.checked {
  background: url('../../../public/images/preferencial_seleccionado.png') no-repeat;
  border-radius: 0px;
  line-height: 0px;
  border: 0px;
  width: 20px;
  height: 20px;
}

.butaca.nodisponible {
  background: url('../../../public/images/nodisponible.png') no-repeat;
  cursor: not-allowed;
  border-radius: 0px;
  line-height: 0px;
  border: 0px;
  width: 20px;
  height: 20px;
}

</style>
