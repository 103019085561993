<template lang="pug">
  v-card(v-if="!error")
    v-expansion-panels(accordion multiple flat v-model="accordion")
      v-expansion-panel
        v-expansion-panel-header
          v-card-title.py-0 Detalles del viaje
        v-expansion-panel-content
          v-card-text
            v-row(justify="center")
              v-col(cols="12" sm="6")
                v-card(outlined)
                  v-card-text.text-center
                    div.text-center.text-h5.font-weight-light Viaje de ida
                    OrigenDestino(:origen="origen" :destino="destino")
                    div.mt-3.text-overline.primary--text Fecha
                    div.mt-n2.text-body-2 {{ $moment(itinerario[0].zarpe).format('dddd LL') }}
                    div.text-overline.primary--text Hora
                    div.mt-n2.text-body-2 {{ $moment(itinerario[0].zarpe).format('HH:mm A') }}
                    div.text-overline.primary--text Nave
                    div.mt-n2.text-body-2 {{ itinerario[0].recurso.nombre }}
              v-col(v-if="itinerario[1]" cols="12" sm="6")
                v-card(outlined)
                  v-card-text.text-center
                    div.text-center.text-h5.font-weight-light Viaje de regreso
                    OrigenDestino(:origen="destino" :destino="origen")
                    div.mt-3.text-overline.primary--text Fecha
                    div.mt-n2.text-body-2 {{ $moment(itinerario[1]?.zarpe).format('dddd LL') }}
                    div.text-overline.primary--text Hora
                    div.mt-n2.text-body-2 {{ $moment(itinerario[1]?.zarpe).format('HH:mm A') }}
                    div.text-overline.primary--text Nave
                    div.mt-n2.text-body-2 {{ itinerario[1].recurso.nombre }}
      v-expansion-panel(v-if="pasajeros.length > 0")
        v-expansion-panel-header
          v-card-title.py-0
            v-chip.mr-2(color="secondary" small) {{ pasajeros.length }}
            div Pasajeros
            v-spacer
            v-skeleton-loader(v-if="tarifando" loading type="chip")
            div(v-else) {{ totalPasajeros | currency('$ ', 0, { thousandsSeparator: '.' })}}
        v-expansion-panel-content
          v-card-text
            v-row(justify="space-around")
              v-col.px-0.px-sm-3(v-for="(pax, p) in pasajeros" :key="p" cols="12" sm="6" lg="4")
                v-card.text-center(outlined)
                  v-card-title.pb-2
                    v-icon.mr-2(color="secondary") mdi-account
                    div.primary--text.text-body-1 Pasajero {{ p + 1 }}
                  v-card-text
                    div.text-overline.primary--text Nombre
                    div.mt-n2.text-body-2 {{ pax.nombre }} {{ pax.apellido }}
                    div.text-overline.primary--text {{ pax.tipoDoc }}
                    div.mt-n2.text-body-2 {{ pax.rut }}
                    div.text-overline.primary--text Género
                    div.mt-n2.text-body-2 {{ pax.genero }}
                    div.text-overline.primary--text Fecha de nacimiento
                    div.mt-n2.text-body-2 {{ cumpleaños(pax.cumpleano) }}
                    div.text-overline.primary--text(v-if="butaca_ida") Butaca Ida
                    div.mt-n2.text-body-2(v-if="butaca_ida") {{ pax.butacas[0] }}
                    div.text-overline.primary--text(v-if="butaca_regreso") Butaca Regreso
                    div.mt-n2.text-body-2(v-if="butaca_ida") {{ pax.butacas[1] }}
                    div.text-overline.primary--text Tarifa
                    div.mt-n2.text-body-2 {{ tarifas[pax.tag]?.precios?.[0]?.nombre }}
                    v-row.mt-3(justify="center" no-gutters)
                      v-col(v-if="tipo_viaje === 'vuelta'" cols="6")
                        div.text-overline.primary--text IDA
                        div.centrar(v-if="tarifando")
                          v-skeleton-loader(loading type="text" max-width="70")
                        div.mt-n2.text-body-1(v-else) {{ tarifas[pax.tag].precios[0]?.monto | currency('$ ', 0, { thousandsSeparator: '.' })}}
                      v-col(v-if="tipo_viaje === 'vuelta'" cols="6")
                        div.text-overline.primary--text REGRESO
                        div.centrar(v-if="tarifando")
                          v-skeleton-loader(loading type="text" max-width="70")
                        div.mt-n2.text-body-1(v-else) {{ tarifas[pax.tag].precios[1]?.monto | currency('$ ', 0, { thousandsSeparator: '.' })}}
                      v-col(cols="6")
                        div.text-overline.primary--text TOTAL
                        div.centrar(v-if="tarifando")
                          v-skeleton-loader(loading type="chip")
                        div.mt-n2.text-h6(v-else) {{ tarifas[pax.tag]?.total | currency('$ ', 0, { thousandsSeparator: '.' })}}
      v-expansion-panel(v-if="vehiculos.length > 0")
        v-expansion-panel-header
          v-card-title.py-0
            v-chip.mr-2(color="secondary" small) {{ vehiculos.length }}
            div Vehículos
            v-spacer
            v-skeleton-loader(v-if="tarifando" loading type="chip")
            div(v-else) {{ totalVehiculos | currency('$ ', 0, { thousandsSeparator: '.' })}}
        v-expansion-panel-content
          v-card-text
            v-row(justify="space-around")
              v-col.px-0.px-sm-3(v-for="(veh, v) in vehiculos" :key="v" cols="12" sm="6" lg="4")
                v-card.text-center(outlined)
                  v-card-title.pb-2
                    v-icon.mr-2(color="secondary") {{ getIcon(veh) }}
                    div.primary--text.text-body-1 Vehículo {{ v + 1 }}
                  v-card-text
                    div.text-overline.primary--text Tipo
                    div.mt-n2.text-body-2 {{ getTipo(veh) }}
                    div.text-overline.primary--text Patente
                    div.mt-n2.text-body-2 {{ veh.patente }}
                    div.text-overline.primary--text Conductor
                    div.mt-n2.text-body-2 {{ veh.conductor }}
                    div(v-if="veh.tipo === 'vmayor' || veh.carro")
                      div.text-overline.primary--text(v-if="veh.tipo === 'vmayor'") Largo total
                      div.text-overline.primary--text(v-else) Largo carro de arrastre
                      div.mt-n2.text-body-2 {{ veh.largo }} m
                    // div.text-overline.primary--text Tarifa
                    // div.mt-n2.text-body-2 {{ tarifas[veh.tag]?.precios?.[0]?.nombre }}
                    v-row.mt-3(justify="center" no-gutters)
                      v-col(v-if="tipo_viaje === 'vuelta'" cols="6")
                        div.text-overline.primary--text IDA
                        div.centrar(v-if="tarifando")
                          v-skeleton-loader(loading type="text" max-width="70")
                        div.mt-n2.text-body-1(v-else) {{ calcular_tarifa_vehiculo(veh, 0) | currency('$ ', 0, { thousandsSeparator: '.' })}}
                      v-col(v-if="tipo_viaje === 'vuelta'" cols="6")
                        div.text-overline.primary--text REGRESO
                        div.centrar(v-if="tarifando")
                          v-skeleton-loader(loading type="text" max-width="70")
                        div.mt-n2.text-body-1(v-else) {{ calcular_tarifa_vehiculo(veh, 0) | currency('$ ', 0, { thousandsSeparator: '.' })}}
                      v-col(cols="6")
                        div.text-overline.primary--text TOTAL
                        div.centrar(v-if="tarifando")
                          v-skeleton-loader(loading type="chip")
                        div.mt-n2.text-h6(v-else) {{ calcular_tarifa_vehiculo(veh, 'total') | currency('$ ', 0, { thousandsSeparator: '.' })}}
      v-expansion-panel(v-if="cargas.length > 0")
        v-expansion-panel-header
          v-card-title.py-0
            v-chip.mr-2(color="secondary" small) {{ cargas.length }}
            div Cargas
            v-spacer
            v-skeleton-loader(v-if="tarifando" loading type="chip")
            div(v-else) {{ totalCargas | currency('$ ', 0, { thousandsSeparator: '.' })}}
        v-expansion-panel-content
          v-card-text
            v-row(justify="space-around")
              v-col.px-0.px-sm-3(v-for="(car, c) in cargas" :key="c" cols="12" sm="6" lg="4")
                v-card.text-center(outlined)
                  v-card-title.pb-2
                    v-icon.mr-2(color="secondary") {{ getIcon(car) }}
                    div.primary--text.text-body-1 Carga {{ c + 1 }}
                  v-card-text
                    div.text-overline.primary--text Tipo
                    div.mt-n2.text-body-2 {{ getTipo(car) }}
                    div(v-if="car.tipo === 'carga'")
                      div.text-overline.primary--text Peso
                      div.mt-n2.text-body-2 {{ car.peso }} kg
                    // div.text-overline.primary--text Tarifa
                    // div.mt-n2.text-body-2 {{ tarifas[car.tag]?.precios?.[0]?.nombre }}
                    v-row.mt-3(justify="center" no-gutters)
                      v-col(v-if="tipo_viaje === 'vuelta'" cols="6")
                        div.text-overline.primary--text IDA
                        div.centrar(v-if="tarifando")
                          v-skeleton-loader(loading type="text" max-width="70")
                        div.mt-n2.text-body-1(v-else) {{ tarifas[car.tag].precios[0]?.monto | currency('$ ', 0, { thousandsSeparator: '.' })}}
                      v-col(v-if="tipo_viaje === 'vuelta'" cols="6")
                        div.text-overline.primary--text REGRESO
                        div.centrar(v-if="tarifando")
                          v-skeleton-loader(loading type="text" max-width="70")
                        div.mt-n2.text-body-1(v-else) {{ tarifas[car.tag].precios[1]?.monto | currency('$ ', 0, { thousandsSeparator: '.' })}}
                      v-col(cols="6")
                        div.text-overline.primary--text TOTAL
                        div.centrar(v-if="tarifando")
                          v-skeleton-loader(loading type="chip")
                        div.mt-n2.text-h6(v-else) {{ tarifas[car.tag]?.total | currency('$ ', 0, { thousandsSeparator: '.' })}}
    WidgetDTE(ref="WidgetDTE" :p="pasajeros.length" :v="vehiculos.length" :c="cargas.length")
    WidgetContacto(ref="WidgetContacto")
    v-row.mt-6(justify="center")
      v-col.text-center(cols="auto")
        div.text-body-1.font-weight-medium.mb-2 Total a pagar
        v-skeleton-loader(v-if="tarifando" loading type="chip")
        div.text-h3.primary--text(v-else) {{ total | currency('$ ', 0, { thousandsSeparator: '.' }) }}
    v-row.my-3(justify="center")
      v-col.mx-auto(cols="auto")
        v-checkbox(v-model="acepto" :error="aceptoErr" :error-messages="aceptoErrMsg")
          template(v-slot:label)
            div Acepto los <a href="https://www.ferrypatagonia.com/terminos-y-condiciones" target="_blank" @click.stop>términos y condiciones comerciales</a>.
    v-card-actions
      v-btn.mx-3.mb-3(color="primary" @click="$emit('prev')", :disabled="loading || tarifando")
        v-icon(left) mdi-chevron-left
        | Volver
      v-spacer
      v-btn.mx-3.mb-3.pagar_sf(color="secondary" @click="reservar" :loading="loading" :disabled="tarifando")
        | reservar
        v-icon(right) mdi-chevron-right
    v-dialog(v-model="reservarModal" persistent max-width="600")
      v-card
        v-card-text.pt-6
          v-row(justify="center")
            v-col(cols="auto")
              v-progress-circular(v-if="reservando" indeterminate size="80" color="secondary")
              v-icon(v-else-if="sindisponibilidad" size="80" color="warning") mdi-alert-outline
              v-icon(v-else-if="duplicado" size="80" color="warning") mdi-account-switch
              v-icon(v-else size="80" color="secondary") mdi-check
          v-row(justify="center")
            v-col.text-center(v-if="reservando" cols="auto")
              div.text-h6 Estamos preparando su reserva...
            v-col.text-center(v-else-if="sindisponibilidad" cols="auto")
              div.text-h6 ¡Se acabaron los cupos!
              div.text-body-1 Los cupos se agotan rápido.
              div.text-body-1 Por favor revise otro horario para viajar.
            v-col.text-center(v-else-if="duplicado" cols="auto")
              div.text-h6 Pasajeros o vehículos en viaje
              div.text-body-1 Algunos pasajeros y/o vehículos ya están reservados para este viaje.
              div.text-body-1 Revise los datos o intente nuevamente en algunos minutos.
            v-col.text-center(v-else cols="auto")
              div.text-h6 ¡Su reserva está lista!
              div.text-h6 Nº Reserva: {{ reserva.id }}
              div.text-body-1.mt-6 Tiene 10 minutos para realizar el pago
          v-row(justify="center")
            v-col
              form(method="POST" :action="tbk?.url" name="frmpago")
                input(type="hidden" name="token_ws" :value="tbk?.token")
        v-card-actions
          v-btn(v-if="sindisponibilidad || duplicado" color="primary" @click="$router.push('/')")
            v-icon(left) mdi-chevron-left
            | Revisar
          v-spacer
          v-btn.mx-3.mb-3.pagar_sf(color="secondary" @click="pagar" :disabled="reservando || sindisponibilidad || duplicado" :loading="pagarLoading")
            | ir a pagar
            v-icon(right) mdi-chevron-right
  v-card(v-else)
    v-card-title Ha ocurrido un error
    v-card-text
      v-row
        v-col
          div.my-3.body-1 Lo sentimos. No pudimos procesar su reserva.
          div.my-3.body-1 Revise sus datos e intente nuevamente.
          div.my-3.body-1 Si el problema persiste, comuníquese con su agencia más cercana.
    v-card-actions
      v-btn.mx-3.mb-3(color="primary" @click="$emit('prev')", :disabled="loading")
        v-icon(left) mdi-chevron-left
        | Volver
      v-spacer
</template>

<script>

import WidgetDTE from '@/components/UI/WidgetDTE'
import WidgetContacto from '@/components/UI/WidgetContacto'
import OrigenDestino from '@/components/UI/OrigenDestino'

export default {
  name: 'Detalle',

  components: {
    WidgetDTE,
    WidgetContacto,
    OrigenDestino
  },

  data: () => ({
    tab: 0,
    accordion: [0],
    activo: null,
    pagarLoading: false,
    reservarModal: false,
    tarifando: false,
    reservando: false,
    sindisponibilidad: false,
    duplicado: false,
    acepto: false,
    aceptoErr: false,
    aceptoErrMsg: '',
    tbk: {},
    reserva: {}
  }),

  watch: {
    acepto () {
      this.aceptoErr = false
      this.aceptoErrMsg = ''
    }
  },

  computed: {
    itinerario () {
      return this.$store.state.itinerario
    },

    tipo_viaje () {
      return this.$store.state.tipo_viaje
    },

    origen () {
      if (this.tab === 0) {
        return this.$store.state.origen
      } else {
        return this.$store.state.destino
      }
    },

    destino () {
      if (this.tab === 0) {
        return this.$store.state.destino
      } else {
        return this.$store.state.origen
      }
    },

    pasajeros () {
      return this.$store.state.pasajerosArr
    },

    vehiculos () {
      return this.$store.state.vehiculosArr
    },

    cargas () {
      return this.$store.state.cargasArr
    },

    tarifas () {
      return this.$store.state.tarifas?.items || {}
    },

    total () {
      if (this.$store.state.tarifas) {
        return this.$store.state.tarifas?.total || 0
      } else {
        return 0
      }
    },

    totalPasajeros () {
      let total = 0
      for (let p = 0; p < this.pasajeros.length; p++) {
        total += this.tarifas[this.pasajeros[p].tag]?.total
      }
      return total
    },

    totalVehiculos () {
      let total = 0
      for (let v = 0; v < this.vehiculos.length; v++) {
        const veh = this.vehiculos[v]
        if (veh.carro) {
          total += this.tarifas[veh.tag]?.total + this.tarifas[veh.tag_carro]?.total
        } else {
          total += this.tarifas[veh.tag]?.total
        }
      }
      return total
    },

    totalCargas () {
      let total = 0
      for (let c = 0; c < this.cargas.length; c++) {
        total += this.tarifas[this.cargas[c].tag]?.total
      }
      return total
    },

    error () {
      if (!this.tarifando) {
        return !this.$store.state.tarifas?.ok
      } else {
        return false
      }
    },

    loading () {
      return this.$store.state.datosPagoLoading || this.pagarLoading
    },

    butaca_ida () {
      return this.itinerario?.[0]?.butacas || false
    },
    butaca_regreso () {
      return this.itinerario?.[1]?.butacas || false
    }
  },

  async mounted () {
    this.tarifando = true
    this.$store.commit('DISABLED_INTERFACE', true)
    await this.$store.dispatch('tarifas')
    this.tarifando = false
    this.$store.commit('DISABLED_INTERFACE', false)
  },

  methods: {
    cumpleaños (fecha) {
      if (!fecha) {
        return ''
      }

      const edad = this.$moment(fecha)

      return edad.format('LL') + ' (' + this.$moment().diff(edad, 'years') + ' años)'
    },

    tipo (t) {
      if (t === 1) {
        return 'vuelta'
      } else {
        return 'ida'
      }
    },

    getIcon (item) {
      switch(item.tipo) {
        // Vehículos
        case 'vmenor':
          return 'mdi-car'
        case 'moto':
          return 'mdi-motorbike'
        case 'vmayor':
          return 'mdi-truck'
        // Cargas
        case 'bici':
          return 'mdi-bicycle'
        case 'vacuno':
          return 'mdi-cow'
        case 'carga':
          return 'mdi-weight'
        case 'sobre':
          return 'mdi-email'
      }
      return 'mdi-help'
    },

    getTipo (item) {
      switch(item.tipo) {
        // Vehículos
        case 'vmenor':
          return 'Vehículo menor'
        case 'moto':
          return 'Motos y motonetas'
        case 'vmayor':
          return 'Vehículo mayor'
        // Cargas
        case 'bici':
          return 'Bicicleta'
        case 'vacuno':
          return 'Vacunos y/o caballos'
        case 'carga':
          return 'Carga general'
        case 'sobre':
          return 'Sobres'
      }
      return 'Desconocido'
    },

    calcular_tarifa_vehiculo (veh, index) {
      if (!veh) {
        return 0
      }
      if (veh.carro) {
        if (index === 'total') {
          return (this.tarifas[veh.tag]?.total || 0) + (this.tarifas[veh.tag_carro]?.total || 0)
        }
        return this.tarifas[veh.tag].precios[index]?.monto + this.tarifas[veh.tag_carro].precios[index]?.monto
      }

      if (index === 'total') {
        return this.tarifas[veh.tag]?.total || 0
      }
      return this.tarifas[veh.tag].precios[index]?.monto
    },

    async reservar () {
      const v1 = this.$refs['WidgetContacto'].validar()
      const v2 = this.$refs['WidgetDTE'].validar()

      this.sindisponibilidad = false
      if (v1 && v2) {
        if (!this.acepto) {
          this.$store.dispatch('notify', {
              color: 'error',
              text: 'Debe aceptar los Términos y Condiciones Comerciales',
              icon: 'mdi-alert-circle',
              timeout: 5000
            })
          this.aceptoErr = true
          this.aceptoErrMsg = 'Requerido'
          return
        }
        this.reservarModal = true
        this.reservando = true
        const res = await this.$store.dispatch('reservar')

        this.reservando = false

        if (res.error) {
          switch (res.message) {
            case 'No hay disponibilidad':
              this.sindisponibilidad = true
              break
            case 'Items duplicados':
              this.duplicado = true
              break
            default: this.$store.dispatch('notify', {
                color: 'error',
                text: 'Error. Intente nuevamente',
                icon: 'mdi-alert-circle',
                timeout: 5000
              })
              this.reservarModal = false
              break;
          }
        } else {
          this.tbk = res.data.tbk
          this.reserva = res.data.reserva

          if (this.total === 0) {
            // Pagar directamente
            this.pagarLoading = true
            const pagado = await this.$store.dispatch('pagar', this.tbk?.token)
            if (pagado.status === 200 && pagado.data) {
              this.$router.push('/final?resultado=' + pagado.data)
            }
          }
        }
      } else {
        this.$store.dispatch('notify', {
          color: 'error',
          text: 'Complete todos los datos requeridos',
          icon: 'mdi-alert-circle',
          timeout: 5000
        })
      }
    },

    async pagar () {
      this.pagarLoading = true
      document.frmpago.submit()
    }
  }
}

</script>

<style type="text/css">

.pagar_sf span {
  pointer-events: none;
}

.centrar {
  text-align: -webkit-center;
}

</style>
