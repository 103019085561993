<template lang="pug">
  v-card(outlined)
    v-card-title Cargas
    v-card-text
      div.mb-6.text-h6.font-weight-light(v-if="cargas.length > 0") Complete los datos de las cargas:
      div.mb-6.text-h6.font-weight-light(v-else) Sin cargas
      v-expansion-panels(v-model="activo" style="z-index: 0")
        v-expansion-panel(v-for="(carga, v) in cargas", :key="v")
          v-expansion-panel-header(:disable-icon-rotate="tiene_error(v) !== 0")
            v-row(align="center")
              v-col.d-flex(cols="auto")
                v-icon.mr-2(color="secondary") {{ icono(carga) }}
                div.primary--text.text-body-1.font-weight-medium Carga {{ v + 1 }}
            template(v-if="tiene_error(v) === 0" v-slot:actions)
              v-icon $expand
            template(v-else-if="tiene_error(v) === 1" v-slot:actions)
              v-icon(color="error") mdi-alert-circle
            template(v-else v-slot:actions)
              v-icon(color="success") mdi-check
          v-expansion-panel-content
            v-form(:ref="'form_carga_' + v" :disabled="disabled")
              v-row(align="center")
                v-col(cols="12" sm="6")
                  v-select(label="Tipo" v-model="carga.tipo" item-text="nombre" item-value="id" :items="tipos" filled color="secondary" @change="onChange" required :rules="rules(true)")
                v-col(v-if="carga.tipo === 'carga'" cols="12" sm="6")
                  v-text-field(label="Peso" v-model="carga.peso" filled color="secondary" required :rules="rules(true, 'cantidad')" :validate-on-blur="!first_time"  placeholder="15" suffix="kg." type="number" min="0" @change="onChange")
                v-col(v-else-if="carga.tipo" cols="12" sm="6")
                  v-text-field(label="Cantidad" v-model="carga.cantidad" filled color="secondary" required :rules="rules(true, 'cantidad')" placeholder="15" suffix="un." type="number" min="1" :validate-on-blur="!first_time" @change="onChange")


</template>

<script>

export default {
  name: 'WidgetCargas',

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  components: {
  },

  data: () => ({
    first_time: true,
    activo: 0,
    conductores: [],
    tipos: [
      {
        id: 'bici',
        nombre: 'Bicicleta',
        icono: 'mdi-bicycle'
      },
      {
        id: 'vacuno',
        nombre: 'Vacunos y/o caballos',
        icono: 'mdi-cow'
      },
      {
        id: 'carga',
        nombre: 'Carga general',
        icono: 'mdi-weight'
      },
      {
        id: 'sobre',
        nombre: 'Sobres',
        icono: 'mdi-email'
      }
    ]
  }),

  watch: {
  },

  computed: {
    cargas () {
      return this.$store.state.cargasArr
    }
  },

  methods: {
    icono (carga) {
      for (let t = 0; t < this.tipos.length; t++) {
        if (this.tipos[t].id === carga.tipo) {
          return this.tipos[t].icono
        }
      }
      return 'mdi-help'
    },

    validar () {
      if (this.cargas.length === 0) {
        return true
      }
      this.first_time = false
      let validos = []
      for (let c = 0; c < this.cargas.length; c++) {
        if (c === this.activo) {
          validos.push(this.validacion_form_manual(c))
        } else {
          validos.push(this.validacion_manual(c))
        }
      }
      this.$forceUpdate()
      return validos.reduce((a, b) => a && b)
    },

    validacion_form_manual (c) {
      const v1 = this.$refs['form_carga_' + c]
      if (v1) {
        const valido = v1[0].validate()
        this.cargas[c].error = !valido
        return !this.cargas[c].error
      }
      this.cargas[c].error = true
      return !this.cargas[c].error
    },

    validacion_manual (c) {
      if (this.cargas[c].tipo === '') {
        this.cargas[c].error = true
        return !this.cargas[c].error
      }
      if (this.cargas[c].tipo === 'carga') {
        this.cargas[c].error = !this.check(this.cargas[c].peso)
        return !this.cargas[c].error
      }
      this.cargas[c].error = !this.check(this.cargas[c].cantidad)
      return !this.cargas[c].error
    },

    check (n) {
      if (n === undefined || n === null) {
        return false
      }

      if (n < 1) {
        return false
      }

      return true
    },

    tiene_error (c) {
      if (this.first_time) {
        return 0
      }
      if (this.cargas[c].error) {
        return 1
      }
      return 2
    },

    onChange () {
      this.$store.commit('SET_TARIFAS_VIGENTES', false)
    }
  }
}

</script>

<style type="text/css">

</style>
