<template lang="pug">
  v-card
    v-card-text
      v-row.mt-0(justify="center" fill-height no-gutters)
        v-col.text-center
          v-icon(size="60" color="secondary") mdi-check-circle
      v-row.mb-6(justify="center" fill-height no-gutters)
        v-col.text-center
          div.text-md-h4.text-h6.secondary--text(class="text-h4") Transacción exitosa
      v-row.white--text(justify="center")
        v-col(cols="12")
          v-card(color="primary")
            v-card-text
              v-row.white--text(justify="center")
                v-col.text-center(cols="12")
                  v-row(justify="center")
                    v-col(cols="auto")
                      div.text-h5 Detalles de la transacción
                v-col.white--text.text-md-h6.text-body-1.font-weight-medium(cols="12")
                  v-row(v-if="resultado.num_reserve" dense align="center")
                    v-col(cols="6")
                      div.text-right.text-body-2 Nº Reserva:
                    v-col(cols="6")
                      div {{ resultado.num_reserve }}
                  v-row(v-if="resultado.code_reserve" dense align="center")
                    v-col(cols="6")
                      div.text-right.text-body-2 Nº Orden de compra:
                    v-col(cols="6")
                      div {{ resultado.code_reserve }}
                  v-row(dense align="center")
                    v-col(cols="6")
                      div.text-right.text-body-2 Monto:
                    v-col(cols="6")
                      div(v-if="resultado.monto === 0") Liberado
                      div(v-else) {{ resultado.monto | currency('$ ', 0, { thousandsSeparator: '.' }) }}
                  v-row(v-if="resultado.auth_code" dense align="center")
                    v-col(cols="6")
                      div.text-right.text-body-2 Código de autorización:
                    v-col(cols="6")
                      div {{ resultado.auth_code }}
                  v-row(v-if="resultado.fecha" dense align="center")
                    v-col(cols="6")
                      div.text-right.text-body-2 Fecha:
                    v-col(cols="6")
                      div {{ $moment(resultado.fecha, 'DD-MM-YYYY').format('LL') }}
                  v-row(v-if="resultado.hora" dense align="center")
                    v-col(cols="6")
                      div.text-right.text-body-2 Hora:
                    v-col(cols="6")
                      div {{ resultado.hora }}
                  v-row(v-if="resultado.tipo_pago" dense align="center")
                    v-col(cols="6")
                      div.text-right.text-body-2 Tipo de pago:
                    v-col(cols="6")
                      div {{ tipo_pago(resultado.tipo_pago) }}
      v-row.mt-6(justify="center")
        v-col.text-center(cols="12")
          v-progress-linear.my-3(v-if="loadingTickets" indeterminate color="secondary" width="210")
          v-btn.my-3.pa-6(v-else color="secondary" shaped :href="tickets" target="_blank") Descargar tickets
      v-row(v-if="resultado.correo" justify="center" no-gutters)
        v-col(cols="12")
          div.text-body-1.text-center(v-if="loadingTickets") Estamos generando sus tickets...</b>
          div.text-body-1.text-center Se envió una copia a <b>{{ resultado.correo }}</b>
          v-img.mt-6.mx-auto.text-center(src="tree.png" width="50" contain)
          div.text-body-1.text-center No es necesario imprimir tus tickets. Puedes presentarlos directamente desde tu celular.
          div.text-body-1.text-center ¡Cuidemos juntos la Patagonia!
      v-row.mt-6(justify="center")
        v-col(cols="12")
          v-card(href="https://www.ferrypatagonia.com/blog" target="_blank")
            v-img.text-center.align-center.white--text(src="images/panoramas.jpg" height="400")

</template>

<script>

export default {
  name: 'App',
  data: () => ({
    correo: '',
    correoLoading: false,
    emailRules: [
      v => v.trim() === '' || /.+@.+\..+/.test(v) || 'Correo no válido',
    ],
    resultado: {},
    cuotas: false,
    exito: false,
    loadingTickets: true
  }),

  computed: {
    tickets () {
      if (this.resultado && this.resultado.uid) {
        return `https://transal-tickets.s3-us-west-2.amazonaws.com/ticket_${this.resultado.uid}.pdf`
      }
      return null
    }
  },

  mounted( ) {

    let b64 = this.$route.query.resultado
    if( !b64 ) {
      this.$router.push('/');
      return
    }
    let str
    try {
      str = atob(b64)
    } catch( err ) {
      this.$router.push('/');
      return
    }
    this.resultado = JSON.parse(str)
    this.resultado.auth_code = this.resultado.auth_code === '000000' ? undefined : this.resultado.auth_code
    this.cuotas = this.resultado.tipo_pago && this.resultado.tipo_pago !== 'VD' && this.resultado.tipo_pago !== 'VP'
    this.exito = this.resultado.exito

    setTimeout(() => {
      this.loadingTickets = false
    }, 20000)
    // this.conversion()
  },

  methods: {
    conversion () {
      if (this.resultado.gtm) {
        this.resultado.gtm.forEach(element => {
          element.currency = 'CLP'
        })
      }
      this.$gtm.trackEvent({
        event: 'purchase', // Event type [default = 'interaction'] (Optional)
        transaction_id: this.resultado.code_reserve,
        value: Number(this.resultado.monto),
        language: navigator.language,
        currency: 'CLP',
        items: this.resultado.gtm
      })
    },

    tipo_pago( tipo ) {
      switch(tipo) {
        case 'VD': return 'Tarjeta de débito';
        case 'VN':
        case 'VC':
        case 'SI':
        case 'S2':
        case 'NC': return 'Tarjeta de cŕedito';
        case 'VP': return 'Tarjeta de prepago'
      }
      return 'Otro'
    },

    tipo_cuotas( tipo ) {
      switch(tipo) {
        case 'VD': return 'Tarjeta de débito';
        case 'VN': return 'Sin cuotas';
        case 'VC': return 'Cuotas normales';
        case 'SI':
        case 'S2':
        case 'NC': return 'Sin interés';
      }
      return 'Otros'
    }
  }
}
</script>

<style type="text/css">

  .pacifico {
    font-family: 'Pacifico', cursive !important;
    font-size: x-large;
  }

</style>