<template lang="pug">
  v-snackbar(v-model="$store.state.notify_flag", :color="$store.state.notify_color", :timeout="$store.state.notify_timeout" app top)
    template(v-slot:default)
      v-row(no-gutters align="center")
        v-col(cols="auto")
          v-icon {{ $store.state.notify_icon }}
        v-col.ml-2
          div {{ $store.state.notify_text }}
      v-row(v-if="$store.state.notify_timeout <= 0" no-gutters)
        v-spacer
        v-col(cols="auto")
          v-btn(text @click="$store.state.notify_flag = false") OK
      v-progress-linear.mt-2(v-else :buffer-value="$store.state.notify_progress" color="white" height="3")
</template>

<script>

export default {
  name: 'Notify'
}

</script>
