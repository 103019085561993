<template lang="pug">
div.pt-3.d-flex.justify-center.text-center.text-body-1
  div(style="width: 120px;")
    v-icon(color="secondary" large) mdi-ferry
    div.text-overline.primary--text Desde
    div.mt-n1.text-body-1 {{ origen?.nombre || '' }}
  div(style="width: 100%; text-align: -webkit-center;")
    div.linea.mt-8
  div(style="width: 120px;")
    v-icon(color="secondary" large) mdi-map-marker
    div.text-overline.primary--text Hasta
    div.mt-n1.text-body-1 {{ destino?.nombre || ''}}
</template>

<script>

export default {
  name: 'OrigenDestino',

  props: {
    origen: {
      type: Object,
      default: null
    },

    destino: {
      type: Object,
      default: null
    },
  },

  components: {
  },

  data: () => ({
  }),

  watch: {
  },

  computed: {
  },

  methods: {
  }
}

</script>

<style type="text/css">

</style>
