<template lang="pug">
  v-slide-x-reverse-transition
    v-card(v-if="!oculto")
      v-card-title
        div Reserva
      v-card-text
        v-row(dense)
          v-expand-transition
            v-col(cols="12" v-if="origen")
              v-text-field(filled rounded dense hide-details label="Origen" v-model="origen" readonly)
          v-expand-transition
            v-col(cols="12" v-if="destino")
              v-text-field(filled rounded dense hide-details label="Destino" v-model="destino" readonly)
          v-expand-transition
            v-col(cols="12" v-if="$store.state.fecha_ida")
              v-text-field(filled rounded dense hide-details label="Fecha de ida" v-model="ida" readonly)
          v-expand-transition
            v-col(cols="12" v-if="$store.state.tipo_viaje === 'vuelta' && $store.state.fecha_regreso")
              v-text-field(filled rounded dense hide-details label="Fecha de regreso" v-model="regreso" readonly)
          v-col(cols="6")
            v-text-field(filled rounded dense hide-details label="Pasajeros", :value="total('pasajeros')" readonly)
          v-col(cols="6")
            v-text-field(filled rounded dense hide-details label="Vehículos", :value="total('vehículos')" readonly)
        v-row(dense)
          v-spacer
          v-col(cols="auto")
            v-expand-transition
              v-btn(text rounded color="primary" v-if="$route.path !== '/'" @click="editar" :disabled="disabled")
                v-icon(left) mdi-pencil
                | Editar
</template>

<script>

export default {
  name: 'Resumen',

  components: {
  },

  data: () => ({
  }),

  watch: {
  },

  computed: {
    oculto () {
      const oculto = false
      /* const oculto = !this.$store.state.destino
            && !this.$store.state.origen
            // && this.$store.state.fecha_ida === ''
            && this.$store.state.fecha_regreso === ''
            && this.$store.state.pasajerosArr.length === 0
            && this.$store.state.vehiculosArr.length === 0 */
      if (!oculto) {
        this.$emit('mostrar')
      }
      return oculto
    },

    ida () {
      if (this.$store.state.itinerario && this.$store.state.itinerario[0]) {
        return this.$moment(this.$store.state.itinerario[0].zarpe).format('LL, HH:mm A')
      }
      return this.$moment(this.$store.state.fecha_ida).format('LL')
    },

    regreso () {
      if (this.$store.state.itinerario && this.$store.state.itinerario[1]) {
        return this.$moment(this.$store.state.itinerario[1].zarpe).format('LL, HH:mm A')
      }
      return this.$moment(this.$store.state.fecha_regreso).format('LL')
    },

    origen () {
      return this.$store.state.origen?.nombre || ''
    },

    destino () {
      return this.$store.state.destino?.nombre || ''
    },

    disabled () {
      return this.$store.state.disabled_interface
    }
  },

  methods: {
    editar () {
      this.$emit('cerrar')
      this.$router.push('/')
    },

    total (tipo) {
      let n
      if (tipo === 'pasajeros') {
        n = this.$store.state.pasajerosArr.length
      }
      if (tipo === 'vehículos') {
        n = this.$store.state.vehiculosArr.length
      }

      if (n > 0) {
        return n
      }

      return 'Sin ' + tipo
    },

    tipo (i) {
      if (i === 1) {
        return 'vuelta'
      }
      return 'ida'
    }
  }
}

</script>

<style type="text/css">

</style>
