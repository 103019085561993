<template lang="pug">
  v-card#Tarjeta1
    v-card-text
      v-form(ref="form" v-model="valid")
        v-row(align="center", justify="start")
          v-radio-group.ml-3(v-model="tipo_viaje" row hide-details)
            v-radio(label="Solo ida" color="secondary" value="ida")
            v-radio(label="Ida y vuelta" color="secondary" value="vuelta")
        v-row(align="center", justify="center")
          v-col(cols="12" sm="6")
             v-select(label="Origen" v-model="origen" outlined :items="origenesArr" item-text="nombre" return-object required :rules="rules(true)" no-data-text="No hay puertos disponibles")
          v-col(cols="12" sm="6")
            v-select(label="Destino" v-model="destino" outlined, :items="destinosArr" item-text="nombre" return-object :disabled="!origen" required :rules="rules(true)" no-data-text="No hay puertos disponibles")
        v-row(align="center", justify="center")
          v-col(cols="12" sm="6")
            v-menu(v-model="menu_ida" offset-y transition="scale-transition" min-width="290px", :nudge-right="30", :close-on-content-click="false")
              template(v-slot:activator="{ on }")
                v-text-field(outlined label="Ida" v-on="on" readonly v-model="fecha_ida_label" required, :rules="rules(true)")
              v-date-picker(@input="menu_ida = false" v-model="fecha_ida" locale="es" first-day-of-week="1" color="primary", :min="today")
          v-col(cols="12" sm="6")
            v-menu(v-model="menu_regreso" offset-y transition="scale-transition" min-width="290px", :nudge-right="30", :close-on-content-click="false")
              template(v-slot:activator="{ on }")
                v-text-field(outlined label="Regreso" v-on="on" readonly v-model="fecha_regreso_label", :disabled="tipo_viaje !== 'vuelta'", :required="tipo_viaje === 'vuelta'", :rules="rules(tipo_viaje === 'vuelta')")
              v-date-picker(@input="menu_regreso = false" v-model="fecha_regreso" locale="es" first-day-of-week="1" color="primary", :min="fecha_ida")
        v-row(align="center")
          v-col(cols="12" sm="6")
            NumericInput(label="Pasajeros" v-model="pasajeros" @incrementar="agregar('pasajeros')" @decrementar="sacar('pasajeros')", :error="!pasajeros_validos()" info="Se debe ingresar a todos los pasajeros.")
          v-col(cols="12" sm="6")
            NumericInput(label="Vehículos" v-model="vehiculos" @incrementar="agregar('vehiculos')" @decrementar="sacar('vehiculos')" max="5" info="Vehículos menores, mayores, motocicletas, etc.")
          v-col(cols="12" sm="6")
            NumericInput(label="Cargas" v-model="cargas" @incrementar="agregar('cargas')" @decrementar="sacar('cargas')" max="5" info="Bicicletas, vacunos, caballos, encomiendas y/o carga general.")
        // v-expand-transition
          VehiculosTab(v-if="vehiculos > 0" ref="vehiculos_tab")
        v-row
          v-col
            v-btn(block large color="primary" @click="validar", :loading="itinerariosLoading") Buscar viajes

</template>

<script>

import NumericInput from '@/components/UI/NumericInput'
import VehiculosTab from '@/components/UI/VehiculosTab'

export default {
  name: 'Paso0',

  components: {
    NumericInput,
    VehiculosTab
  },

  data: () => ({
    destinoDisabled: true,
    valid: true,
    validar_form: false,
    menu_ida: false,
    menu_regreso: false,
    q: {},
    autocompletar: false,
    today: ''
  }),

  watch: {
    /* async origen (val) {
      if(val !== undefined ){
        // this.resetearDestinos()
        // this.destinoLoading = true
        this.destinoDisabled = false
        await this.$store.dispatch('getDestinos', this.origen).catch(() => {
          this.$store.dispatch('notifyErrorServer')
        })
        if(this.autocompletar && this.destinosArr.includes(this.q.d)) {
          this.destino = this.q.d
        }
        // this.destinoLoading = false
      } else {
        this.$store.commit('SET_ORIGENES', [])
        // this.resetearOrigenes()
      }
    }, */

    fecha_ida (val) {
      if (this.fecha_regreso === '') {
        return
      }
      const ida = this.$moment(val)
      if (ida.isAfter(this.fecha_regreso)) {
        this.fecha_regreso = ''
      }
    },
  },

  computed: {
    tipo_viaje: {
      get () {
        return this.$store.state.tipo_viaje
      },

      set (val) {
        this.$store.commit('SET_TIPO_VIAJE', val)
      }
    },

    origen: {
      get () {
        return this.$store.state.origen
      },

      set (val) {
        this.$store.commit('SET_ORIGEN', val)
        this.$store.commit('SET_DESTINO', null)
      }
    },

    destino: {
      get () {
        return this.$store.state.destino
      },

      set (val) {
        this.$store.commit('SET_DESTINO', val)
      }
    },

    fecha_ida: {
      get () {
        return this.$store.state.fecha_ida
      },

      set (val) {
        this.$store.commit('SET_FECHA_IDA', val)
      }
    },

    fecha_ida_label () {
      return this.$moment(this.fecha_ida).format('LL')
    },

    fecha_regreso: {
      get () {
        return this.$store.state.fecha_regreso
      },

      set (val) {
        this.$store.commit('SET_FECHA_REGRESO', val)
      }
    },

    fecha_regreso_label () {
      if (!this.fecha_regreso) {
        return
      }
      return this.$moment(this.fecha_regreso).format('LL')
    },

    origenesArr () {
      return this.$store.state.puertos || []
    },

    destinosArr () {
      if (!this.$store.state.origen) {
        return []
      }

      const arr = []
      for (let o = 0; o < this.origen.destinos.length; o++) {
        const destino = this.origen.destinos[o].id
        for (let p = 0; p < this.origenesArr.length; p++) {
          if (this.origenesArr[p].id === destino) {
            arr.push(this.origenesArr[p])
          }
        }
      }
      return arr
    },

    destinoLoading () {
      if (this.destinoDisabled) {
        return false
      }
      return this.$store.state.destinos === null
    },

    vehiculos () {
      return this.$store.state.vehiculosArr.length
    },

    pasajeros () {
      return this.$store.state.pasajerosArr.length
    },

    cargas () {
      return this.$store.state.cargasArr.length
    },

    itinerarios () {
      return this.$store.state.itinerarios
    },

    itinerariosLoading () {
      return this.$store.state.itinerarios === null
    },

    itinerario () {
      return this.$store.state.itinerario
    }
  },

  async mounted () {
    this.verificar_query()
    this.today = this.$moment().format('YYYY-MM-DD')
    if (this.fecha_ida === '') {
      this.fecha_ida = this.today
    }

    if (!this.$store.state.puertos) {
      await this.$store.dispatch('puertos').catch(() => {
        this.$store.dispatch('notifyErrorServer')
      })
    }

    if(this.autocompletar && this.origenesArr.includes(this.q.o)) {
      this.origen = this.q.o
    }

    /* this.$store.dispatch('getMarcas').catch(() => {
      this.$store.dispatch('notifyErrorServer')
    }) */
  },

  methods: {
    verificar_query() {
      this.q = {...this.$route.query}
      if(Object.keys(this.q).length > 0) {
        this.$router.push('/')
      }
      if(this.q.d && this.q.o && this.q.p && this.q.v) {
        this.autocompletar = true
        const p = parseInt(this.q.p)
        if(!isNaN(p) && p >= 0 && p <= 99) {
          for(let pp = 0; pp < p; pp++) {
            this.agregar('pasajeros')
          }
        }

        const v = parseInt(this.q.v)
        if(!isNaN(v) && v >= 0 && v <= 5) {
          for(let vv = 0; vv < v; vv++) {
            this.agregar('vehiculos')
          }
        }
      }
    },

    agregar (tipo) {
      if (tipo === 'pasajeros') {
        this.$store.commit('AGREGAR_PASAJERO')
        return
      }
      if (tipo === 'vehiculos') {
        this.$store.commit('AGREGAR_VEHICULO')
        return
      }
      if (tipo === 'cargas') {
        this.$store.commit('AGREGAR_CARGA')
        return
      }
    },

    sacar (tipo) {
      this.$store.commit('SACAR', tipo)
    },

    validar () {
      this.validar_form = true
      const pax = this.pasajeros_validos()
      const veh = this.vehiculos_validos()
      if (this.$refs.form.validate() && pax && veh) {
        this.buscar()
      } else {
        this.$store.dispatch('notify', {
          color: 'error',
          text: 'Complete todos los datos requeridos',
          icon: 'mdi-alert-circle',
          timeout: 5000
        })
      }
    },

    pasajeros_validos () {
      if (!this.validar_form) {
        return true
      }
      return this.pasajeros > 0
    },

    vehiculos_validos () {
      /* if (this.vehiculos > 0) {
        return this.$refs.vehiculos_tab.validar()
      } */
      return true
    },

    concatenar (atr, handler) {
      return this.$store.state.vehiculosArr.map(el => handler ? handler(el[atr]) : el[atr]).join(',')
    },

    categoria (tipo) {
      switch (tipo) {
        case 'menor':
          return 'Vehículo Menor'
        case 'bus':
          return 'Motorhome'
        case 'moto':
          return 'Motocicleta'
        case 'bici':
          return 'Bicicleta'
      }
      return ''
    },

    async buscar () {
      const obj = {
        tipo: this.tipo_viaje,
        origen: this.origen.id,
        destino: this.destino.id,
        fecha: this.fecha_ida,
        regreso: this.fecha_regreso,
      }

      await this.$store.dispatch('eventos', obj).then(() => {
        this.$emit('next')
      })
      .catch(() => {
        this.$store.dispatch('notifyErrorServer')
      })
    }
  }
}

</script>

<style>

</style>