import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment-timezone'
// import qs from 'qs'
import { v4 as uuidv4 } from 'uuid'

axios.defaults.baseURL = 'https://api.ferrypatagonia.com/api/webapp/'
const EXPIRACION_RESERVA = 20

Vue.use(Vuex)

//to handle state
const state = {
  EXPIRACION_RESERVA,
  session_id: null,
  paso: 0,
  tipo_viaje: 'ida',
  fecha_ida: '',
  fecha_regreso: '',
  origen: null,
  destino: null,
  puertos: null,
  disabled_interface: false,
  nacionalidades: null,
  vehiculosArr: [],
  pasajerosArr: [],
  cargasArr: [],
  itinerarios: undefined,
  itinerario: [null, null],
  butacas: [null, null],
  nominaPaxLoading: false,
  nominaVehLoading: false,
  tarifas: null,
  tarifasLoading: false,
  tarifasVigentes: false,
  dte: 'ninguno',
  notify_flag: false,
  notify_text: '',
  notify_color: '',
  notify_icon: '',
  notify_timeout: -1,
  notify_progress: 0,
  notify_timer: undefined,
  datosFactura: {},
  datosContacto: {},
  indexContacto: 0,
}

//to handle state
const getters = {}

//to handle actions
const actions = {
  async puertos({ commit }) {
    commit('SET_PUERTOS', null)

    const puertos = await axios.get('puertos')

    commit('SET_PUERTOS', puertos.data)
    return puertos.data
  },
  async eventos({ commit }, data) {
    commit('SET_ITINERARIOS', null)
    // commit('SET_ITINERARIO', [null, null])

    const itinerarios = await axios.get('eventos', {
      params: data
    })

    const corte = moment().tz('America/Santiago').add(60, 'minutes')
    for (let i = 0; i < itinerarios.data.length; i++) {
      for (let ii = 0; ii < itinerarios.data[i].length; ii++) {
        const fecha = moment(itinerarios.data[i][ii].zarpe).tz('America/Santiago')
        if (fecha.isBefore(corte)) {
          itinerarios.data[i].splice(ii, 1)
          ii--
        }
      }
    }

    commit('SET_ITINERARIOS', itinerarios.data)
  },

  async butacas({ commit, state }, data) {
    commit('SET_BUTACAS', [null, null])

    const butacas = await axios.post('butacas', {
      eventos: data,
      origen: state.origen.id,
      destino: state.destino.id
    })

    commit('SET_BUTACAS', butacas.data)

    return butacas.data
  },

  async tarifas({ commit, state }) {
    if (state.tarifasVigentes) {
      return state.tarifas
    }
    commit('SET_TARIFAS_LOADING', true)
    const vehiculos = []
    for (let v = 0; v < state.vehiculosArr.length; v++) {
      const veh = { ...state.vehiculosArr[v] }
      vehiculos.push(veh)
      if (veh.carro) {
        vehiculos.push({
          tipo: 'metrolineal',
          tag: veh.tag_carro,
          largo: veh.largo
        })
        delete veh.carro
        delete veh.largo
      }
    }
    const array = limpiar([...state.pasajerosArr, ...vehiculos, ...state.cargasArr])
    const items = {}

    for (let a = 0; a < array.length; a++) {
      items[array[a].tag] = array[a]
      delete items[array[a].tag].tag
    }

    const data = {
      i: items,
      e: [state.itinerario[0].id]
    }

    if (state.tipo_viaje === 'vuelta') {
      data.e.push(state.itinerario[1].id)
    }

    if (state.tarifas && state.tarifas.uid) {
      data.a = state.tarifas.uid
    }

    data.o = state.origen.id
    data.d = state.destino.id

    const tarifas = await axios.post('tarifas', data)

    commit('SET_TARIFAS_LOADING', false)
    commit('SET_TARIFAS', tarifas.data)
    commit('SET_TARIFAS_VIGENTES', true)
  },

  async reservar({ state }) {
    const data = {
      uid: state.tarifas.uid,
      contacto: state.datosContacto,
      tipo_dte: state.dte
    }

    if (state.datosFactura && Object.keys(state.datosFactura).length > 0) {
      data.datos_factura = state.datosFactura
    }

    const res = await axios.post('reservas', data).catch(error => {
      const err = {
        error: true,
        message: error.response.statusText,
        data: error.response.data
      }
      return err
    })

    return res
  },

  async pagar({ commit }, token) {

    const res = await axios.post('liberado', { token }).catch(e => {
      return e.response
    })
    commit('DUMMY')
    return res
  },

  async persona({ commit }, rut) {

    const response = await axios.get('persona?rut=' + rut).catch(() => {
      return null
    })
    commit('DUMMY')
    return response?.data?.data || null
  },

  notifyErrorServer ({ dispatch }) {
    const payload = {
      color: 'error',
      text: 'Hubo un error. Por favor intente nuevamente',
      icon: 'mdi-alert-circle',
      timeout: 7000
    }
    dispatch('notify', payload)
  },

  notify ({ commit }, payload) {
    commit('SET_NOTIFY', payload)
  },

  resetNotify ({ commit }) {
    commit('RESET_NOTIFY')
  },

  timeover ({ commit, state }) {
    state.timeover_modal = false
    state.timeover_minutos = EXPIRACION_RESERVA
    clearInterval(state.timeover_timer)
    state.timeover_timer = null
    state.timeover_ts = null
    state.primera_reserva = true

    commit('SET_TEMP_PRERESERVA', null)
    commit('SET_PRERESERVA', [null, null])
    commit('SET_ITINERARIO', [null, null])
    commit('SET_PRERESERVA_LOADING', false)
    commit('SET_NOMINAR_PAX_LOADING', false)
    commit('SET_NOMINAR_VEH_LOADING', false)
    commit('SET_TARIFAS_LOADING', false)
    commit('SET_DATOS_PAGO_LOADING', false)
    commit('SET_CODIGO', undefined)
    commit('SET_CODIGO_LOADING', false)
    commit('SET_INSCRIBIR_SOCIO', false)
    commit('RESET_NOTIFY')
    commit('SET_INDEX_CONTACTO', 0)
    commit('SET_PASO', 0)
  }
}

//to handle mutations
const mutations = {
  DUMMY() {
  },

  SET_PASO(state, paso) {
    state.paso = paso
  },

  SET_TIPO_VIAJE(state, tipo_viaje) {
    state.tipo_viaje = tipo_viaje
  },

  SET_PUERTOS(state, puertos) {
    state.puertos = puertos
  },

  SET_ORIGEN(state, origen) {
    state.origen = origen
  },

  SET_DESTINO(state, destino) {
    state.destino = destino
  },

  SET_FECHA_IDA(state, fecha_ida) {
    state.fecha_ida = fecha_ida
  },

  SET_FECHA_REGRESO(state, fecha_regreso) {
    state.fecha_regreso = fecha_regreso
  },

  SET_NACIONALIDADES(state, nac) {
    state.nacionalidades = nac
  },

  AGREGAR_VEHICULO(state) {
    const data = {
      tipo: '',
      tag: short_uuid(),
      tag_carro: short_uuid(),
      carro: false,
      largo: undefined,
    }
    state.vehiculosArr.push(data)
    state.tarifasVigentes = false
  },

  AGREGAR_PASAJERO(state) {
    const data = {
      tipoDoc: 'RUT',
      tipo: 'persona',
      tag: short_uuid(),
      disabled: true,
      loading: false,
      buscado: false
    }
    state.pasajerosArr.push(data)
    state.tarifasVigentes = false
  },

  AGREGAR_CARGA(state) {
    const data = {
      tipo: '',
      tag: short_uuid(),
      peso: undefined,
      cantidad: 1
    }
    state.cargasArr.push(data)
    state.tarifasVigentes = false
  },

  LIMPIAR_BUTACAS(state) {
    for (let p = 0; p < state.pasajerosArr.length; p++) {
      delete state.pasajerosArr[p].butacas
    }
  },

  SACAR(state, tipo) {
    if (tipo === 'pasajeros') {
      state.pasajerosArr.pop()
      return
    }
    if (tipo === 'vehiculos') {
      state.vehiculosArr.pop()
      return
    }
    if (tipo === 'cargas') {
      state.cargasArr.pop()
      return
    }
  },

  SET_ITINERARIOS(state, itinerarios) {
    state.itinerarios = itinerarios
  },

  SET_ITINERARIO(state, itinerario) {
    state.itinerario = itinerario
    state.tarifasVigentes = false
  },

  SET_BUTACAS(state, butacas) {
    state.butacas = butacas
  },

  SET_TARIFAS(state, tarifas) {
    state.tarifas = tarifas
  },

  SET_TARIFAS_LOADING(state, tarifasLoading) {
    state.tarifasLoading = tarifasLoading
  },

  SET_TARIFAS_VIGENTES(state, vigentes) {
    state.tarifasVigentes = vigentes
  },

  SET_DTE(state, dte) {
    state.dte = dte
  },

  SET_DATOS_FACTURA(state, datos) {
    state.datosFactura = datos
  },

  SET_INDEX_CONTACTO(state, datos) {
    state.indexContacto = datos
  },

  SET_DATOS_CONTACTO(state, datos) {
    state.datosContacto = datos
  },


  SET_PASAJERO(state, data) {
    const index = data.index
    const tipo = data.tipo
    if (index >= 0 && index < state.pasajerosArr.length) {
      state.pasajerosArr[index][tipo] = {...state.pasajerosArr[index][tipo], ...data.data}
    }
  },

  SET_BUTACA_PASAJERO(state, data) {
    const index = data.index
    const butacas = data.data.butacas
    if (index >= 0 && index < state.pasajerosArr.length) {
      state.pasajerosArr[index].butacas = butacas
    }
  },

  LIMPIAR_PASAJERO(state, id) {
    const tipoDoc = state.pasajerosArr[id].tipoDoc
    const tag = state.pasajerosArr[id].tag
    const tipo = state.pasajerosArr[id].tipo
    state.pasajerosArr[id] = {
      tipoDoc,
      tag,
      tipo,
      disabled: true,
      loading: false,
      buscado: false
    }
    state.tarifasVigentes = false
  },

  RESETEAR_CONDUCTORES (state) {
    for(let p = 0; p < state.pasajerosArr.length; p++) {
      state.pasajerosArr[p].pIsDriver = false
    }
  },


  SET_NOTIFY (state, payload) {
    state.notify_color = payload.color || state.notify_color
    state.notify_text = payload.text || state.notify_text
    state.notify_icon = payload.icon || state.notify_icon
    state.notify_flag = true
    state.notify_timeout = payload.timeout > 0 ? payload.timeout : -1
    state.notify_progress = 0
    if (payload.timeout > 0) {
      clearInterval(state.notify_timer)
      state.notify_timer = setInterval(() => {
        state.notify_progress++
        if (state.notify_progress >= 100) {
          clearInterval(state.notify_timer)
          state.notify_progress = 0
        }
      }, state.notify_timeout / 100)
    } else {
      state.notify_timeout = -1
    }
  },

  RESET_NOTIFY (state) {
    state.notify_flag = false
    state.notify_color = 'success'
    state.notify_text = ''
    state.notify_icon = 'mdi-check'
    state.notify_timeout = -1
    state.notify_progress = 0
    clearInterval(state.notify_timer)
    state.notify_timer = undefined
  },

  DISABLED_INTERFACE (state, disabled) {
    state.disabled_interface = disabled
  }
}

function short_uuid () {
  return uuidv4().split('-')[0]
}

function limpiar (arr) {
  const arr2 = JSON.parse(JSON.stringify(arr))

  for (let i = 0; i < arr2.length; i++) {
    const limpio = arr2[i]
    delete limpio.disabled
    delete limpio.loading
    delete limpio.buscado
    delete limpio.onblur
    delete limpio.error
    delete limpio.form_rut
    delete limpio.driver
    delete limpio.tag_carro

    if (limpio.peso) {
      limpio.peso = parseFloat(limpio.peso)
    }

    if (limpio.largo) {
      limpio.largo = parseFloat(limpio.largo)
    }
  }

  return arr2
}


//export store module
export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})
