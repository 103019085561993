<template lang="pug">
  div
    v-scale-transition(origin="center center 0")
      v-btn.scroll-to-top(v-show="showScrollToTop" fab dark color="primary" @click="scrollToTop")
        v-icon mdi-arrow-up
</template>

<script>

export default {
  data () {
    return {
      showScrollToTop: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll () {
      // Mostrar el botón después de haber hecho scroll hacia abajo 100px
      this.showScrollToTop = window.scrollY > 100
    },

    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style>
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}
</style>
