<template lang="pug">
  Itinerarios(@next="next" @prev="prev")
</template>

<script>

import Itinerarios from '@/components/UI/Itinerarios'

export default {
  name: 'Paso1',

  components: {
    Itinerarios
  },

  data: () => ({
  }),

  watch: {
  },

  computed: {
  },

  methods: {
    prev () {
      this.$store.commit('SET_PASO', 1)
      this.$router.push('/')
    },

    next () {
      this.$store.commit('SET_PASO', 2)
      this.$router.push('/paso2')
    }
  }
}

</script>

<style type="text/css">

</style>
