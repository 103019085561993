<template lang="pug">
  v-card
    v-card-text
      WidgetVehiculos.mt-3(ref="wVehiculos" :disabled="loading")
      WidgetCargas.mt-3(ref="wCargas" :disabled="loading")

    v-card-actions
      v-btn.mx-3.mb-3(color="primary" @click="$emit('prev')" :disabled="loading")
        v-icon(left) mdi-chevron-left
        | Volver
      v-spacer
      v-btn.mx-3.mb-3(color="secondary" @click="validar()" :loading="loading")
        | Continuar
        v-icon(right) mdi-chevron-right

  // v-card(v-else)
    v-card-title Ocurrió un error
    v-card-text
      v-row
        v-col
          div.my-3.body-1 Lo sentimos, no pudimos generar su reserva.
          div.my-3.body-1 Por favor revise sus datos e intente nuevamente.
          div.my-3.body-1 Si el problema persiste, comuníquese con su agencia más cercana.
    v-card-actions
      v-btn.mx-3.mb-3(color="primary" @click="$emit('prev')", :disabled="loading")
        v-icon(left) mdi-chevron-left
        | Volver
      v-spacer
</template>

<script>

import WidgetCargas from '@/components/UI/WidgetCargas'
import WidgetVehiculos from '@/components/UI/WidgetVehiculos'

export default {
  name: 'PasajerosVehiculos',

  components: {
    WidgetCargas,
    WidgetVehiculos
  },

  data: () => ({
    loading: false
  }),

  watch: {
  },

  computed: {
    vehiculos () {
      return this.$store.state.vehiculosArr?.length || 0
    },

    cargas () {
      return this.$store.state.cargasArr?.length || 0
    }
  },

  methods: {
    async validar () {
      const v1 = this.cargas > 0 ? this.$refs['wCargas'].validar() : true
      const v2 = this.vehiculos > 0 ? this.$refs['wVehiculos'].validar() : true

      if (v1 && v2) {
        this.$emit('next')
      } else {
        this.$store.dispatch('notify', {
          color: 'error',
          text: 'Complete todos los datos requeridos',
          icon: 'mdi-alert-circle',
          timeout: 5000
        })
      }
    }
  }
}

</script>

<style type="text/css">

</style>
