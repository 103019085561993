/* eslint-disable array-bracket-spacing */
/* eslint-disable no-multi-spaces */
export default {
  'la tehuelche': {
    title: 'La Tehuelche',
    src: 'assets/imgs/queulat.jpg',
    model: [
      [   1,   2, '_',  31,  32, '_',  61,  62, '_',  91,  92, '_', '_', '_', 121, 122, '_', 151, 152, '_', 181, 182, '_', 211, 212 ],
      [   3,   4, '_',  33,  34, '_',  63,  64, '_',  93,  94, '_', '_', '_', 123, 124, '_', 153, 154, '_', 183, 184, '_', 213, 214 ],
      [   5,   6, '_',  35,  36, '_',  65,  66, '_',  95,  96, '_', '_', '_', 125, 126, '_', 155, 156, '_', 185, 186, '_', 215, 216 ],
      [   7,   8, '_',  37,  38, '_',  67,  68, '_',  97,  98, '_', '_', '_', 127, 128, '_', 157, 158, '_', 187, 188, '_', 217, 218 ],
      [   9,  10, '_',  39,  40, '_',  69,  70, '_',  99, 100, '_', '_', '_', 129, 130, '_', 159, 160, '_', 189, 190, '_', 219, 220 ],
      [  11,  12, '_',  41,  42, '_',  71,  72, '_', 101, 102, '_', '_', '_', 131, 132, '_', 161, 162, '_', 191, 192, '_', 221, 222 ],
      [  13,  14, '_',  43,  44, '_',  73,  74, '_', 103, 104, '_', '_', '_', 133, 134, '_', 163, 164, '_', 193, 194, '_', 223, 224 ],
      [  15,  16, '_',  45,  46, '_',  75,  76, '_', 105, 106, '_', '_', '_', 135, 136, '_', 165, 166, '_', 195, 196, '_', 225, 226 ],
      [  17,  18, '_',  47,  48, '_',  77,  78, '_', 107, 108, '_', '_', '_', 137, 138, '_', 167, 168, '_', 197, 198, '_', 227, 228 ],
      [  19,  20, '_',  49,  50, '_',  79,  80, '_', 109, 110, '_', '_', '_', 139, 140, '_', 169, 170, '_', 199, 200, '_', 229, 230 ],
      [  21,  22, '_',  51,  52, '_',  81,  82, '_', 111, 112, '_', '_', '_', 141, 142, '_', 171, 172, '_', 201, 202, '_', 231, 232 ],
      [  23,  24, '_',  53,  54, '_',  83,  84, '_', 113, 114, '_', '_', '_', 143, 144, '_', 173, 174, '_', 203, 204, '_', 233, 234 ],
      [  25,  26, '_',  55,  56, '_',  85,  86, '_', 115, 116, '_', '_', '_', 145, 146, '_', 175, 176, '_', 205, 206, '_', 235, 236 ],
      [  27,  28, '_',  57,  58, '_',  87,  88, '_', 117, 118, '_', '_', '_', 147, 148, '_', 177, 178, '_', 207, 208, '_', 237, 238 ],
      [  29,  30, '_',  59,  60, '_',  89,  90, '_', 119, 120, '_', '_', '_', 149, 150, '_', 179, 180, '_', 209, 210, '_', 239, 240 ]
    ]
  }
}
