<template lang="pug">
  VehiculosCargas(@next="next" @prev="prev")
</template>

<script>

import VehiculosCargas from '@/components/UI/VehiculosCargas'

export default {
  name: 'Paso3',

  components: {
    VehiculosCargas
  },

  data: () => ({
  }),

  watch: {
  },

  computed: {
  },

  methods: {
    prev () {
      this.$store.commit('SET_PASO', 2)
      this.$router.push('/paso2')
    },

    next () {
      this.$store.commit('SET_PASO', 4)
      this.$router.push('/paso4')
    }
  }
}

</script>

<style type="text/css">

</style>
