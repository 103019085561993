<template lang="pug">
  v-app
    Notify
    // v-system-bar(color="error" app style="place-content: center" dark height="60")
      a.text-body-1.text-center.text-decoration-none.white--text(href="https://www.ferrypatagonia.com/post/27-09-cierre-temporal-de-los-embarcaderos" target="_blank")
        div.font-weight-black PUERTO CERRADO
        div Nueva evaluación sábado 28 de septiembre
    v-app-bar(color="primary" dark app)
      v-spacer.hidden-sm-and-down
      router-link(to="/")
        v-row(no-gutters align="center")
          v-col
            v-img(alt="Logo" contain src="images/logo.svg" height="48" max-width="90")
          v-col
            div.text-h6.text-md-h4.white--text TRANSAL
      v-spacer
      v-slide-x-reverse-transition
        v-btn.hidden-md-and-up(text v-if="mostrar_boton" @click="mostrar_resumen = true")
          v-icon(left) mdi-playlist-edit
          | Reserva

    Breadcrumbs.flotante(v-if="$route.path.startsWith('/paso')" v-bind:class=" $vuetify.breakpoint.smAndDown ? 't56' : 't64'")
    v-main.grey.lighten-3.mt-12
      v-container.fill-height(fluid)
        v-row(justify="center")
          v-col(cols="12" md="8" xl="6")
            router-view
          v-col.hidden-sm-and-down(v-if="esconder_resumen()" cols="4" lg="3")
            Resumen.fixed-resumen(@mostrar="mostrar_boton = true")
    ScrollToTop
    v-dialog(v-model="mostrar_resumen" transition="dialog-top-transition")
      Resumen(@cerrar="mostrar_resumen = false")
    Footer

</template>

<script>

import Breadcrumbs from '@/components/UI/Breadcrumbs'
import Notify from '@/components/UI/Notify'
import Resumen from '@/components/UI/Resumen'
import Footer from '@/components/UI/Footer'
import ScrollToTop from '@/components/UI/ScrollToTop'

export default {
  name: 'Inicio',

  components: {
    Breadcrumbs,
    Notify,
    Resumen,
    ScrollToTop,
    Footer
  },

  data: () => ({
    mostrar_resumen: false,
    mostrar_boton: false
  }),

  computed: {
  },

  methods: {
    esconder_resumen () {
      return !this.$route.path.startsWith('/final') && !this.$route.path.startsWith('/error') && !this.$route.path.startsWith('/pago')
    },

    resetear () {
      this.mostrar_resumen = false
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style>
  .flotante {
    position: fixed;
    width: 100%;
    z-index: 2;
  }

  .t64 {
    top: 64px;
  }

  .t56 {
    top: 56px;
  }

  /*slide transition*/
  .slide-enter-active ,
  .slide-leave-active {
    transition: transform .4s ease-out, opacity .4s;
  }
  .slide-enter {
    transform: translateX(80%);
    opacity: 0;
  }
  .slide-leave-to {
    transform: translateX(-80%);
    opacity: 0;
  }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fixed-resumen {
  position: fixed;
  margin-right: 12px;
  max-width: 452px;
}
</style>