<template lang="pug">
  Fechas(@next="next" @prev="prev")
</template>

<script>

import Fechas from '@/components/UI/Fechas'

export default {
  name: 'Paso0',

  components: {
    Fechas
  },

  data: () => ({
  }),

  watch: {
  },

  computed: {
  },

  methods: {
    prev () {
      this.$router.push('/')
    },

    next () {
      this.$store.commit('SET_PASO', 1)
      this.$router.push('/paso1')
    }
  }
}

</script>

<style type="text/css">

</style>
