<template lang="pug">
  v-card
    v-card-text
      WidgetPasajeros(ref="wPasajeros" v-if="pasajeros > 0")

    v-card-actions
      v-btn.mx-3.mb-3(color="primary" @click="$emit('prev')")
        v-icon(left) mdi-chevron-left
        | Volver
      v-spacer
      v-btn.mx-3.mb-3(color="secondary" @click="validar()")
        | Continuar
        v-icon(right) mdi-chevron-right

  // v-card(v-else)
    v-card-title Ocurrió un error
    v-card-text
      v-row
        v-col
          div.my-3.body-1 Lo sentimos, no pudimos generar su reserva.
          div.my-3.body-1 Por favor revise sus datos e intente nuevamente.
          div.my-3.body-1 Si el problema persiste, comuníquese con su agencia más cercana.
    v-card-actions
      v-btn.mx-3.mb-3(color="primary" @click="$emit('prev')", :disabled="loading")
        v-icon(left) mdi-chevron-left
        | Volver
      v-spacer
</template>

<script>

import WidgetPasajeros from '@/components/UI/WidgetPasajeros'

export default {
  name: 'PasajerosVehiculos',

  components: {
    WidgetPasajeros
  },

  data: () => ({
  }),

  watch: {
  },

  computed: {
    pasajeros () {
      return this.$store.state.pasajerosArr ? this.$store.state.pasajerosArr.length : 0
    }
  },

  methods: {
    async validar () {
      if (this.$refs['wPasajeros'].validar()) {
        this.$emit('next')
      }
    }
  }
}

</script>

<style type="text/css">

</style>
